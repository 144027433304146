import React, { useContext } from 'react';
import { Modal as BaseModal, Button } from 'antd';
import sad from '../../img/sad.png';
import cheer from '../../img/cheer.png';
import glass from '../../img/glass.png';

import styled, { ThemeContext } from 'styled-components'

const ConfirmModal = ({ visible, onOk, onClose, loading }) => {
    const themeContext = useContext(ThemeContext);

    return <Modal
        backgroundColor={themeContext.darkBlue}
        visible={visible}
        backgroundImage={glass}
        onCancel={onClose}
    >
        <StyledModal>
            <div className="confirm">
                <span>
                    VOCÊ TEM CERTEZA QUE OS SEUS DADOS ESTÃO CORRETOS?
                </span>
                <div>
                    <Button onClick={onClose} disable={!loading}>VOLTAR E CONFERIR</Button>
                    <Button onClick={onOk} loading={loading}>SIM</Button>
                </div>
            </div>
        </StyledModal>
    </Modal>
}

const ErrorModal = ({ visible, onClose }) => {
    const themeContext = useContext(ThemeContext);

    return <Modal
        backgroundColor={themeContext.pink}
        backgroundImage={sad}
        visible={visible}
        onCancel={onClose}
    >
        <StyledModal>
            <div className="error">
                <span>
                    IDENTIFICAMOS UM PROBLEMA NO SEU CADASTRO
                </span>
                <span>
                    Não foi possível concluir o seu cadastro. Por favor, volte a página e tente novamente.
                    Caso o problema persista, entre em contato no Serviço de Atendimento ao Consumidor da Nestlé 
                </span>
                <span>0800 7702 461 - www.nestle.com.br</span>
            </div>
        </StyledModal>
    </Modal>
}

const WarningModal = ({ visible, onClose, message }) => {
    const themeContext = useContext(ThemeContext);

    return <Modal
        backgroundColor={themeContext.yellow}
        backgroundImage={sad}
        visible={visible}
        onCancel={onClose}
    >
        <StyledModal>
            <div>
                <span>
                    IDENTIFICAMOS UM PROBLEMA
                </span>
                <span>{message}</span>
            </div>
        </StyledModal>
    </Modal>
}

const CupomCodeAndCpfModal = ({ visible, onClose }) => {
    const themeContext = useContext(ThemeContext);

    return <Modal
        backgroundColor={themeContext.pink}
        backgroundImage={sad}
        visible={visible}
        onCancel={onClose}
    >
        <StyledModal>
            <div className="error">
                <span>
                    IDENTIFICAMOS UM PROBLEMA NO SEU CADASTRO
                </span>
                <span>
                    Este cupom já foi associado ao seu CPF.
                </span>
            </div>
        </StyledModal>
    </Modal>
}

const SuccessModal = ({ visible, onClose }) => {
    const themeContext = useContext(ThemeContext);

    return <Modal
        backgroundColor={themeContext.green}
        visible={visible}
        backgroundImage={cheer}
        onCancel={onClose}
    >
        <StyledModal>
            <div className="success">
                <span>
                    CADASTRO CONCLUÍDO COM SUCESSO
                </span>
                <span>
                    Escolha a rede de sua preferência e faça sua compra com esse desconto especial. Não esqueça de utilizá-lo em até 30 dias.
                </span>
            </div>
        </StyledModal>
    </Modal>
}

const TermsModal = ({ visible, onClose }) => <Modal
    footer={null}
    title="Termos do programa"
    visible={visible}
    onCancel={onClose}
>
    <h3>REGULAMENTO – PROGRAMA NESTLE HEALTH SCIENCE</h3>

    <p>Em vigor a partir de Outubro de 2020. </p>

    <p>Este Regulamento, bem como quaisquer outros documentos, incluindo a respectiva Política de Privacidade, regulamentam seus direitos e obrigações ao se cadastrar no Programa de Desconto de Nestle Health Science disponibilizado e mantido pela Nestle Health Science, empresa com sede na R Dr Rubens Gomes Bueno, 691, 19 ao 28, Várzea de Baixo, São Paulo. </p>

    <p>O Programa tem por escopo viabilizar um desconto aos pacientes que possuírem o voucher numerado, podendo fazer a compra de até 4 unidades com o desconto concedido em uma única compra mediando ao cadastro do voucher. Este programa visa oferecer acesso e experimentação a novos consumidores da linha de complementos alimentares e suplementos em fibras de Nestlé Health Science. </p>

    <ul>
        <li>
            <strong>QUEM PODE SE CADASTRAR?</strong>

            <p>
                Pode se cadastrar no Programa qualquer pessoa física, maior de 18 (dezoito) anos, que receba um voucher de desconto ofertado por seu médico ou nutricionista, permitindo a compra de até quatro produtos da mesma família de produtos especificados nesse Regulamento.
            </p>
            <p>
                O Programa é válido apenas nas seguintes redes de farmácias e drogarias: Droga Raia, Drogasil, Drogaria São Paulo, Pague Menos, Panvel, Araújo, Drogaria Pacheco e Venâncio.
            </p>
            <p>
                O participante que receber o voucher e efetivar o seu cadastro no Programa, é integralmente responsável pelas informações cadastradas. O paciente é elegível à compra dos produtos na regra estabelecida neste Regulamento desde que elegível por seu médico ou nutricionista ao recebimento do voucher de desconto e desde que cadastre previamente o voucher recebido no site do Programa: www.programadescontonhs.com.br
            </p>
        </li>
        <li>
            <strong>FORMAS DE CADASTRO E INFORMAÇÕES NECESSÁRIAS</strong>

            <p>     
                O cadastro no Programa de Desconto poderá acontecer via site www.programadescontonhs.com.br ou no balcão da loja física da rede participante que possuir integração web. 
            </p>
            <p>
                Para cadastro no Programa deverão ser fornecidos obrigatoriamente os seguintes dados: nome completo do paciente, CPF, data de nascimento, gênero, telefone celular com DDD, e-mail, Cidade, Estado e código do voucher. Por livre e espontânea vontade, o participante poderá indicar o CEP de sua residência, porém, este dado não é obrigatório para efetivar o cadastro.
            </p>
            <p> 
                Ao efetivar o seu cadastro no Programa, o participante declara que leu e aceita todos os termos deste Regulamento. 
            </p>
            <p>
                As informações pessoais fornecidas pelos participantes no âmbito do Programa serão utilizadas exclusivamente para validação de cadastro, ativação do voucher de desconto recebido e utilização do mesmo na rede credenciada de farmácias e drogarias.
            </p>
            <p>
                A Nestle se responsabiliza pela segurança da base de dados do Programa, garantindo sua integridade e confidencialidade, de acordo com a legislação em vigor.
            </p>
            <p> 
                O Participante se responsabiliza integralmente pela veracidade e exatidão das informações fornecidas no âmbito do Programa, seja no ato do cadastro ou compra, sendo de sua exclusiva responsabilidade manter referidas informações atualizadas. 
            </p>
        </li>
        <li>
            <strong>PRODUTOS VINCULADOS AO PROGRAMA E REGRAS DE DESCONTOS</strong>

            <p>     
            Os seguintes produtos da linha nutricional da Nestle serão disponibilizados para compra no âmbito do Programa:
            </p>
            <p>
            A Nestle se reserva ao direito de incluir, modificar ou retirar qualquer produto, regra, desconto ou conteúdo que esteja no programa, independentemente de comunicação prévia e aceite do Participante.
            </p>
            <p>
            Poderão ser adquiridos mediante a utilização de um mesmo voucher até 4 (quatro) unidades da mesma marca/família de produtos. Com isto, caso o Participante adquira unidades de marca/família diferentes e/ou mais de 4 unidades da mesma marca/família, estas serão transacionadas sem a aplicação do percentual de desconto especificado na tabela acima.
            </p>
        </li>
        <li>
            <strong>COMO E ONDE ADQUIRIR SEU PRODUTO</strong>

            <p>
                O paciente deverá adquirir quaisquer dos produtos especificados no item 3 dentro da rede de farmácias e drogarias credenciadas ao Programa especificada no item 2.
            </p>
            <p>
                No ato da compra ou ao entrar em contato com a Central de Atendimento do Programa, o paciente deverá sempre se identificar mediante a informação de seu CPF cadastrado.
            </p>
        </li>
        <li>
            <strong>PRIVACIDADE E PROTEÇÃO DE DADOS PESSOAIS</strong>

            <p>
            A Nestle respeita sua privacidade, e está comprometida com a proteção de seus dados pessoais. Somente utilizaremos seus dados dentro dos limites legais e éticos, para atender a finalidade pelas quais eles foram coletados. Para obter informações sobre como a Nestle coleta e usa seus dados pessoais, bem como as medidas que adotamos para protegê-los, acesse a nossa Política de Privacidade, disponível em https://www.nestle.com.br/politica-de-privacidade
            </p>
        </li>
        <li>
            <strong>DISPOSIÇÕES GERAIS</strong>

            <p>
                O paciente é elegível à compra dos produtos vinculados a este Programa somente por meio de cadastro e habilitação do voucher recebido de seu médico ou nutricionista.
            </p>
            <p>
                É obrigação do Paciente manter o seu e-mail e celular devidamente atualizados no cadastro, e a Nestle não se responsabiliza por quaisquer comunicações enviadas no âmbito do Programa e não recebidas pelo usuário (ou recebidas fora do prazo apropriado) resultante de informações cadastrais desatualizadas, incompletas ou incorretas.
            </p>
            <p>
                Qualquer cláusula ou condição deste Regulamento que, por qualquer razão, venha a ser considerada nula ou ineficaz por qualquer juízo ou tribunal competente, não afetará as demais disposições constantes neste documento, que permanecerão plenamente válidas e vinculantes, gerando efeitos em sua máxima extensão.
            </p>
            <p>
                O participante poderá cancelar sua participação no Programa a qualquer momento e sem necessidade de aviso prévio, devendo entrar em contato com o Serviço de Atendimento ao Cliente – SAC, pelo número 0800 770 2461 
            </p>
            <p>
                A Nestle se reserva do direito de modificar qualquer ponto deste Regulamento ou cancelar o Programa, independentemente de aviso prévio aos Participantes, sendo que a portabilidade de qualquer voucher que não tenha sido ativado e utilizado durante a vigência do Programa não dará ao portador qualquer direito à aquisição de produtos com o desconto.
            </p>
        </li>
        <li>
            <strong>LEGISLAÇÃO E FORO COMPETENTES</strong>
            <p>Estes Regulamento é regido e interpretado sob as leis do Brasil. Fica eleito o foro de domicílio do Participante para dirimir quaisquer questões ou litígios decorrentes do uso do Programa.</p>
        </li>
    </ul>
    <Button onClick={onClose}>Fechar</Button>
</Modal>

const StyledModal = styled.div`
    margin: 30px 0;

    div {
        > span {
            display: block;
            text-align: center;
            font-size: 16px;
            color: white;
        }

        > span:nth-child(1) {
            font-size: 24px;
            margin-bottom: 30px;
        }
    
        div {
            display: flex;
            justify-content: space-around;      
        }
    
        div button {
            border: none;
            border-radius: 0;
            font-weight: bold;
            color: white;
            width: 180px;
        }
    
        div button:nth-child(1) {
            background-color: ${props => props.theme.pink};
        }
    
        div button:nth-child(2) {
            background-color: ${props => props.theme.green}
        }
    }
    
    .success span {
        color: ${props => props.theme.darkBlue}
    }

    @media (max-width: 600px) {
        .confirm div {
            flex-direction: column;

            button {
                margin-bottom: 12px;
                width: 100% ;
            }
        }

        
    }
`;

const Modal = ({ backgroundColor, backgroundImage, visible, children, onCancel }) => {

    return <BaseModal
        visible={visible}
        footer={null}
        title={null}
        wrapClassName="modal"
        bodyStyle={{
            backgroundColor,
            backgroundImage: `url(${backgroundImage})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'right bottom',
            backgroundSize: '30%',
        }}
        onCancel={onCancel}
    >   
        {children}
    </BaseModal>   
}

export { ConfirmModal, ErrorModal, SuccessModal, CupomCodeAndCpfModal, TermsModal, WarningModal }