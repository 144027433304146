import React from 'react';

import styled from 'styled-components'

import banner from '../img/banner.png';

const Banner = () => {
    return <StyledBanner>
        <div>
            <img src={banner} alt='casal sorrindo enquanto olha para a tela de um computador'/>
            <span>PROGRAMA DE DESCONTOS NESTLÉ HEALTH SCIENCE</span>
        </div>
        <span id="products">CONHEÇA AS LINHAS DE PRODUTOS PARTICIPANTES!</span>
        
    </StyledBanner>
}

const StyledBanner = styled.div`
    position: relative;
    overflow: hidden;

    img {
        height: 60vh;
        width: 100%;
        object-fit: cover;
    }  

    > div {
        position: relative;   
    }

    > span {
        color: white;
        background-color: ${props => props.theme.darkBlue};
        padding: 20px;
        font-size: 16px;
        font-weigth: bold;
        display: block;
        text-align: center;
        box-sizing: border-box;
    }

    > div span {
        background-color: rgba(255, 255, 255, 0.5);
        color: ${props => props.theme.darkBlue};
        font-size: 24px;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        max-width: 300px;
        padding: 20px;
    }

    @media (min-width: 600px) {
        > span {
            position: relative;
            font-size: 24px;
            left: 50%;
            transform: translateX(-50%);
            width: 900px;
            max-width: 100%;
        }
    }

    @media (max-width: 600px) {
        img {
            height: 60vh;
            max-width: none !important; 
            position: relative;
         }  

         > div span {
            max-width: 60vw;
            font-size: 20px;
        }
    }
`

export default Banner;