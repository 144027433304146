import React, { useState } from 'react';
import { Input, Button, Alert } from 'antd';

import styled from 'styled-components'
import { getCupomCodeValidationStatus } from '../../requestConfigs';

const RegisterCupomCodeValidator = ({ isCupomCodeValid, changeCupomCodeValidationStatus, cupomCode, setCupomCode }) => {
    const [loading, setLoading] = useState(false);
    const [cupomMessage, setCupomMessage] = useState('');

    const onCupomCodeChange = (e) => {
        setCupomCode(e.target.value);
    }

    const requestCupomCodeValidation = () => {
        return getCupomCodeValidationStatus(cupomCode);
    }

    const validateCupomCode = () => {
        setLoading(true);
        requestCupomCodeValidation()
            .then(async res => {
                const body = await res.json();
                setCupomMessage(body.message);
                const validationStatus = res.status === 200;
                setLoading(false);
                changeCupomCodeValidationStatus(validationStatus);
            }).catch(async err => {
                setLoading(false);
                console.log(err);
            });
    }

    return <StyledCupomCodeValidator>
        <span>CÓDIGO DO CUPOM DE DESCONTO</span>
        <div>
            <Input
                value={cupomCode}
                onChange={onCupomCodeChange}
            />
            { 
                isCupomCodeValid
                && <Alert
                    message={cupomMessage}
                    type="error"
                />
            }
        </div>
        <Button
            onClick={validateCupomCode}
            loading={loading}
        >
            VALIDE AQUI SEU CUPOM
        </Button>
    </StyledCupomCodeValidator>
}

const StyledCupomCodeValidator = styled.div`
    width: 900px;
    max-width: 100vw;
    background-color: ${props => props.theme.darkBlue};
    color: white;
    padding: 0 32px 24px 32px;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: center;

    > * {
        margin-top: 24px;
    }

    > span {
        font-weight: bold;
        margin-top: 46px;
    }
    
    > div {
        margin: 0 10px;
        min-width: 100px;

        flex: 1;
        display: flex;
        flex-direction: column;

        div[role="alert"] {
            width: fit-content;
            margin-top: 8px;
            padding: 4px;
            background-color: red;
            border: none;
            font-size: 12px;
        
            span {
                color: white;
            }
        }
    }

    input {
        margin-top: 41px;
        border-width: 0;
        border-bottom-width: 3px;
        border-color: white;
        background-color: transparent;
        color: white;
        flex: 1;
        font-weight: bold;
        padding: 0;
        align-self: flex-end;
        font-size: 16px;
        outline: none;
        // min-height: 56px
    }

    > button {
        height: auto;
        border: none;
        color: white;
        background-color: ${props => props.theme.lightBlue};
        padding: 12px;
    }

    @media (max-width: 600px) {
        flex-direction: column;
        align-items: stretch !important;
        text-align: center;
    }
`;

export default RegisterCupomCodeValidator;