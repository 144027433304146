import React, { useState } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import styled from 'styled-components'

import RegisterUserForm from './RegisterUserForm';
import RegisterInfos from './RegisterInfos';
import RegisterCupomCodeValidator from './RegisterCupomCodeValidator';

const Register = () => {
    const [isCupomCodeValid, setIsCupomCodeValid] = useState(false);
    const [validationRequested, setValidationRequested] = useState(false);
    const [cupomCode, setCupomCode] = useState('');

    const changeCupomCodeValidationStatus = (newStatus) => {
        setValidationRequested(true);
        setIsCupomCodeValid(newStatus);
    }

    const changeCupomCode = (value) => {
        if (isCupomCodeValid && validationRequested) {
            setIsCupomCodeValid(false); 
            setValidationRequested(false);
        }
        setCupomCode(value);
    }

    const resetForm = () => {
        changeCupomCode('');
    }

    return <StyledRegister  id="registers">
        <RegisterInfos />
        <RegisterCupomCodeValidator
            isCupomCodeValid={!isCupomCodeValid && validationRequested}
            changeCupomCodeValidationStatus={changeCupomCodeValidationStatus}
            cupomCode={cupomCode}
            setCupomCode={changeCupomCode}
        />
        {   
            isCupomCodeValid && validationRequested &&
            <RegisterUserForm cupomCode={cupomCode} resetForm={resetForm}/>
        }
    </StyledRegister>
}

const StyledRegister = styled.div`
    background-color: ${props => props.theme.fadedGray};
    display: flex;
    align-items: center;
    flex-direction: column;
`

export default Register;