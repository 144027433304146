import React, { useState, useEffect } from 'react';

import { Button } from 'antd';
import { DoubleLeftOutlined, DoubleRightOutlined } from '@ant-design/icons';
import styled from 'styled-components'

import touch from '../../img/touch.png';
import grid from '../../img/grid.png';
import discount from '../../img/discount.png';
import papers from '../../img/papers.png';

const initialPanels = [
    <div className="panel">
        <div>
            <span className="step">1</span>
            <img src={grid} alt="grid"/>
            <img src={touch} style={{ right: '0%', left: 'auto', top: '70%' }} alt="touch"/>
        </div>
        <div className="description">
            <span>Insira o código e valide o cupom de desconto</span>
        </div>
        <div className="overlay"></div>
    </div>,
    <div className="panel">
        <div>
            <span className="step">2</span>
            <img src={papers} alt="papers"/>
        </div>
        <div className="description">
            <span>Realize o seu cadastro no programa</span>
        </div>
        <div className="overlay"></div>
    </div>,
    <div className="panel">
        <div>
            <span className="step">3</span>
            <img src={discount} alt="discount"/>
        </div>
        <div className="description">
            <span>Escolha a loja física ou site de um parceiro, informe seu CPF e compre com desconto!</span>
        </div>
        <div className="overlay"></div>
    </div>
    
]

const Carousel = () => {
    const [panels, setPanels] = useState(initialPanels);

    const previousPanel = () => {
        setPanels(prev => {
            const next = [...prev];
            const last = next.pop();
            next.unshift(last);
            return next;
        })
    }

    const nextPanel = () => {
        setPanels(prev => {
            const next = [...prev];
            const first = next.shift();
            next.push(first);
            return next;
        })
    }

    useEffect(previousPanel, []);
    
    return <StyledCarousel>
        <Button onClick={previousPanel}>
            <DoubleLeftOutlined />
        </Button>
        <div className="panels">
            {
                panels.map((panel, index) => <div
                    className={index === 1 && 'active'}
                    onClick={index === 0 ? previousPanel : index === 2 ? nextPanel : null}
                >   
                    {panel}
                </div>)
            }
        </div>
        <Button onClick={nextPanel}>
            <DoubleRightOutlined />
        </Button>
    </StyledCarousel>;
}

const StyledCarousel = styled.div`
    background-color: ${props => props.theme.fadedGray} !important;
    display: flex;
    align-items: center;
    padding: 80px 0px 40px 0 !important;
    max-width: 100vw;
    position: relative; 

    button {
        border: none;
        background-color: transparent;
        font-size: 24px;
        height: auto;
        margin: 10px;
    }

    .panels {
        display: flex;

        > div {
            margin: 10px;
        }

        .panel {
            position: relative;
        }

        .panel > div {
            position: relative;
            background-color: white;
            width: 140px;
            border: solid 3px ${props => props.theme.lightBlue};            
        }

        .panel > div:nth-child(1) {
            height: 100px;
            margin-bottom: 5px;
        }

        .panel .step {
            background-color: ${props => props.theme.lightBlue};
            color: white;
            font-size: 12px;
            position: absolute;
            top: 5px;
            left: 5px;
            padding: 0 5px;
        }

        img {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            max-width: 50% !important;
            max-height: 50% !important;
        }

        > div:nth-child(1) {
            transform-origin: 0% 50%;
        }
    
        > div:nth-child(2) {
            transform-origin: 50% 50%;
        }
    
        > div:nth-child(3) {
            transform-origin: 100% 50%;
        }

        .panel > .overlay {
            width: calc(100%);
            height: calc(100%);
            background-color: white;
            position: absolute;
            opacity: 0.3;
            border: none;
            top: 0;
            left: 0;
        }
    
        .active {
            transform: scale(1.6);
            transition: transform 300ms;
            z-index: 1;
        }
    
        .active .overlay {
            opacity: 0;
        }

        .description {
            padding: 10px;
            font-size: 10px;
        }
    
        .active .description span {
            transform: scale(0.5);
        }

        @media (max-width: 600px) {
            .description {
                font-size: 8px;
            }

            .panel > div {
                width: 80px;
            }
        }
    }

    @media (max-width: 600px) {
        button {
            position: absolute;
            bottom: 0;
            
        }

        button:nth-of-type(1) {
            left: 0;
        }

        button:nth-of-type(2) {
            right: 0;
        }
    }    
`

export default Carousel;