const rootUri = window._env_.REACT_APP_NESTLE_API;

export const getCupomCodeValidationStatus = (cupomCode) =>
    fetch(`${rootUri}/vouchers/${cupomCode}`);
export const registerUser = (user) =>
    fetch(`${rootUri}/patients`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(user),
    });
export const attachVoucherToUser = (body, cpf, cupomCode) =>
    fetch(`${rootUri}/patients/${cpf}/cupom/${cupomCode}`, { 
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body), 
    });