import React from 'react';
import Carousel from './Carousel';

import styled from 'styled-components'

const RegisterInfos = () => {
    return <StyledRegisterInfos>
        <div>
            <span>
                Ative o código de desconto do produto que consta em seu cupom. Veja como é rapido e simples.
            </span>
        </div>
        <Carousel />
        <h1>CADASTRE-SE AGORA!</h1>
    </StyledRegisterInfos>;
}

const StyledRegisterInfos = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    > div { 
        width: 100vw;
        background-color: ${props => props.theme.lightBlue};
        padding: 16px 16px;
        display: flex;
        justify-content: center;
    }

    > div > span {
        display: block;
        width: 750px;
        max-width: 100vw;
        font-size: 24px;
        color: ${props => props.theme.darkBlue};
        padding: 20px;
        text-align: center;
    }

    > div:nth-child(1) span {
        font-size: 16px;
    }

    > div:nth-child(2){
        background-color: transparent;
    }

    @media (min-width: 600px) {
        > div:nth-child(1) span {
            font-size: 24px;
        }
    }
`

export default RegisterInfos;