import React from 'react';

import styled from 'styled-components'

import partners from './partners';

const Partners = () => {
    return <StyledPartners id="partners">
        <h1>ESCOLHA A REDE DE SUA PREFERÊNCIA PARA COMPRA PRESENCIAL</h1>
        <div>
            {
                partners.map(({ logo, name }) => <PartnerLinkLogo logo={logo} name={name}/>)
            }
        </div>
    </StyledPartners>
}

const StyledPartners = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: ${props => props.theme.fadedGray};

    > div {
        position: relative;
        max-width: 900px;
        width: 100%;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;   
    }

    > div::before {
        position: absolute;
        max-width: 100vw;
        width: 100vw;
        content: '';
        background-color: ${props => props.theme.mediumGray};
        height: 160px;
        top: 50%;
        transform: translateY(-50%);
    }
`;

const PartnerLinkLogo = ({ href, logo, name }) => {

    const logoImg = <img src={logo} alt={`link para o site da ${name}`} />;
    

    return <StyledPartnerLinkLogo href={href} target="_blank" rel="noopener noreferrer">
        {
            href
                ? <a href={href} target="_blank" rel="noopener noreferrer">{logoImg}</a>
                : logoImg
        }
    </StyledPartnerLinkLogo>
}

const StyledPartnerLinkLogo = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 180px;
    height: 90px;
    margin: 20px;
    background-color: white;
    z-index: 1;

    img {
        width: 160px;
    }

    @media (max-width: 600px) {
        width: 120px;
        height: 60px;

        img {
            width: 100px;
        }
    }
`

export default Partners;
