import React from 'react';
import 'antd/dist/antd.css';

import styled, { ThemeProvider } from 'styled-components';

import Header from './sections/Header';
import Banner from './sections/Banner'
import Products from './sections/Products';
import Register from './sections/Register/index';
import FAQ from './sections/FAQ/index';
import Partners from './sections/Partners/index';
import Footer from './sections/Footer';

function App() {
  return (
    <ThemeProvider theme={{
      darkBlue: '#001b6b',
      blue: '#0093bf',
      lightBlue: '#00c4e0',
      fadedGray: '#eeeeee',
      lightGray: '#dbdedf',
      mediumGray: '#a0acaf',
      darkGray: '#788890',
      green: '#bad23d',
      pink: '#ff2d6a',
      yellow: '#ffe33d'
    }}>
      <StyledApp>
        <Header />
        <Banner />
        <Products />
        <Register />
        <Partners />
        <FAQ />
        <Footer />
      </StyledApp>
    </ThemeProvider>
  );
}

const StyledApp = styled.div`
  h1 {
    font-size: 24px;
    font-weight: normal;
    color: ${props => props.theme.darkBlue};
    text-align: center;
    margin: 30px 0;
    max-width: 750px;
  }

  img {
    max-width: 100vw;
  }

  div {
    box-sizing: border-box;
  }

  @media (min-width: 600px) {
    h1 {
      font-size: 32px;
    }
  }
`;

export default App;
