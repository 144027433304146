import React, { useState, useContext } from 'react';
import { Button } from 'antd';
import { MenuOutlined, CloseOutlined } from '@ant-design/icons';
import { animateScroll, Link } from 'react-scroll';

import styled, { ThemeContext } from 'styled-components'

import logo from '../img/logo.png';

const Header = () => {
    const themeContext = useContext(ThemeContext);
    const [isDrawerVisible, setIsDrawerVisible] = useState(false);

    const linkDefaultProps = {
        activeClass: "active",
        spy: true,
        smooth: true,
        offset: -100,
        duration: 300,
        onClick: () => { setIsDrawerVisible(false); }
    };

    return <StyledHeader
        padding={14}
        logoHeight={50}
    >
        
        <div className="floating">
            <div>
                <img src={logo} onClick={() => animateScroll.scrollToTop()} alt='logotipo da Nestle Health Science'/>
                <Button onClick={() => setIsDrawerVisible(true)} icon={<MenuOutlined style={{ fontSize: '32px', color: themeContext.darkBlue }}/>}/>
            </div>
            <div className={'drawer'} >
                <div className={'mask'} onClick={() => setIsDrawerVisible(false)} style={{ display: !isDrawerVisible && 'none' }}/>
                <div className={"body"} style={{ right: !isDrawerVisible && -400 }}>
                    <CloseOutlined 
                        onClick={() => setIsDrawerVisible(false)}
                        style={{ color: 'white', position: 'absolute', top: 20, right: 20, fontSize: 24 }}
                    />
                    <div className={"links-wrapper"} >
                        <Link
                            to="products"
                            {...linkDefaultProps}
                        >
                            PRODUTOS
                        </Link>
                        <Link
                            to="registers"
                            {...linkDefaultProps}
                        >
                            CADASTRO
                        </Link>
                        <Link
                            to="partners"
                            {...linkDefaultProps}
                        >
                            PARTICIPANTES
                        </Link>
                        <Link
                            to="faq"
                            {...linkDefaultProps}
                        >
                            DÚVIDAS SOBRE O PROGRAMA
                        </Link>
                    </div>
                </div>
            </div>
        </div>
        <div className="placeholder"/>
    </StyledHeader>
}

const StyledHeader = styled.div`
    .placeholder {
        height: calc(${props => props.padding}px + ${props => props.logoHeight}px + ${props => props.padding}px);
    }

    .floating {
        background-color: rgba(255, 255, 255, 0.8);
        position: fixed;
        left: 50%;
        transform: translateX(-50%);
        width: 100vw;
        display: flex;
        justify-content: center;
        padding: ${props => props.padding}px;
        
        div {
            display: flex;
            justify-content: space-between;
            width: 900px;
            max-width: 100%;
            align-items: center;
        }

        z-index: 2;

        > div img {
            height: ${props => props.logoHeight}px;
            cursor: pointer;
        }

        > div button {
            background-color: transparent;
            border: none;
        }
    }

    .drawer {
        position: fixed;
        top: 0;
        right: 0; 
    }

    .drawer .mask {
        position: fixed;
        width: 100vw;
        height: 100vh;
        left: 0;
        top: 0;
        z-index: -1;
        background-color: rgba(0, 0, 0, 0.3);
        transition: all 0.3s
    }

    .drawer .body {
        position: absolute;
        top: 0;
        right: 0;
        width: 100vw !important;
        max-width: 400px !important;
        height: 300px !important;
        background-color: ${props => props.theme.darkBlue};
        opacity: 0.8;
        display: flex;
        justify-content: center !important;
        transition: all 0.3s
    }

    .drawer .links-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: auto;
    }

    .drawer a {
        color: white;
        margin: 5px 0;
    }
    
    @media (max-width: 600px) {
        .placeholder {
            height: calc(${props => props.padding/2}px + ${props => props.logoHeight/2}px + ${props => props.padding/2}px);
        }

        .floating {
            > div img {
                height: ${props => props.logoHeight/2}px;
            }
    
            padding: ${props => props.padding/2}px;
        }
        
    }
`;

export default Header;