import React from 'react';

export default [
    {
        question: 'Como realizo meu cadastro?',
        answer: 'Olá, você precisa começar inserindo o código de validação do voucher que você recebeu. Se o código estiver correto, automaticamente os campos referentes aos dados pessoais serão liberados. É só seguir preenchendo suas informações pessoais e clicar em enviar.',
    },
    {
        question: 'Quais são os produtos disponíveis no Progama de desconto Nestle Health Science?',
        answer: 'Olá, atualmente temos alguns produtos da Linha Nutren e da Linha Fiber Mais. Na parte inicial do site, você pode visualizar a foto de todos eles.',
    },
    {
        question: 'Como faço para comprar um produto com desconto?',
        answer: 'Para adquirir um produto com desconto, o participante do programa deverá possuir um cupom de desconto com um código válido.'
    },
    {
        question: 'Onde posso comprar os produtos com desconto?',
        answer: 'Você pode comprar os produtos nas redes de farmácias participantes do programa. Veja na página inicial as redes parceiras.'
    },
    {
        question: 'Ainda tenho dúvidas, onde posso esclarecê-las',
        answer: <React.Fragment>Você pode entrar em contato com o Serviço de Atendimento ao Consumidor Nestlé através do 0800 770 2461 ou pelo nosso site <a href="https://www.nestle.com.br" rel="noopener noreferrer" target="_blank">www.nestle.com.br</a></React.Fragment>
    },
    {
        question: 'Informações sobre os produtos participantes do programa.',
        answer: <React.Fragment>Você pode acessar os sites: <a target="_blank" rel="noopener noreferrer" href="https://www.nestlehealthscience.com.br">www.nestlehealthscience.com.br</a> e <a target="_blank" rel="noopener noreferrer" href="https://www.sundownvitaminas.com.br">www.sundownvitaminas.com.br</a> para saber mais!</React.Fragment>
    },
];