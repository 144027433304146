import araujo from '../../img/araujo.png';
import drogasil from '../../img/drogasil.png';
import pacheco from '../../img/pacheco.png';
import pagueMenos from '../../img/pague-menos.png';
import panvel from '../../img/panvel.png';
import raia from '../../img/raia.png';
import saoPaulo from '../../img/sao-paulo.png';
import venancio from '../../img/venancio.png';

export default [
    {
        logo: araujo,
        href: 'https://www.araujo.com.br/busca?fq=H:2251&utm_source=site&utm_medium=portal&utm_content=thrive-portal-na-site-nhsc_retailers-na-na-na-na-nana_na-pbm_retailers_araujo_0110&utm_campaign=IPG_NEST_thrive-portal-na-site-nhsc_retailers-nana_na',
        name: 'drogarias araujo'
    },
    {
        logo: drogasil,
        href: 'https://www.drogasil.com.br/programadedescontos-nhsc.html?utm_source=logo_pbm&utm_medium=portal&utm_content=thrive-portal-na-logo_pbm-nutren_fiber_pbm-logomarca-na-promo-fixo-nana_na-pbm_direcionamento_parceiro_drogasil&utm_campaign=IPG_NEST_thrive-portal-na-logo_pbm-nutren_fiber_pbm-nana_na',
        name: 'drogasil'
    },
    {
        logo: pacheco,
        href: '',
        name: 'drogarias pacheco'
    },
    {
        logo: pagueMenos,
        href: 'https://www.paguemenos.com.br/nutren-fiber?utm_source=logo_pbm&utm_medium=portal&utm_content=thrive-portal-na-logo_pbm-nutren_fiber_pbm-logomarca-na-promo-fixo-nana_na-pbm_direcionamento_parceiro_pague_menos&utm_campaign=IPG_NEST_thrive-portal-na-logo_pbm-nutren_fiber_pbm-nana_na',
        name: 'pague menos'
    },
    {
        logo: panvel,
        href: 'https://www.panvel.com/panvel/promocao.do?promocao=2752&utm_source=site&utm_medium=portal&utm_content=thrive-portal-na-site-nhsc_retailers-na-na-na-na-nana_na-pbm_retailers_panvel_0110&utm_campaign=IPG_NEST_thrive-portal-na-site-nhsc_retailers-nana_na',
        name: 'panvel farmácias'
    },
    {
        logo: raia,
        href: 'https://www.drogaraia.com.br/programadedescontos-nhsc.html?utm_source=logo_pbm&utm_medium=portal&utm_content=thrive-portal-na-logo_pbm-nutren_fiber_pbm-logomarca-na-promo-fixo-as50_na-pbm_direcionamento_parceiro_drogaraia&utm_campaign=IPG_NEST_thrive-portal-na-logo_pbm-nutren_fiber_pbm-as50_na',
        name: 'raia drogaria drugstore'
    },
    {
        logo: saoPaulo,
        href: 'drogaria são paulo',
        name: 'drogaria são paulo',
    },
    {
        logo: venancio,
        href: 'drogaria venancio',
        name: 'drogaria venancio',
    },
];