import React from 'react';

import styled from 'styled-components'

import products from '../img/products.jpg';

const Products = () => {
    return <StyledProducts>
        <img src={products} alt='produtos da nestlé'/>
    </StyledProducts>
}

const StyledProducts = styled.div`
    padding: 20px;
    box-sizing: border-box;

    > img {
        width: 100%;
    }
`

export default Products;