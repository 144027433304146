import React, { useState } from 'react';
import { MailOutlined, PhoneOutlined } from '@ant-design/icons';
import { TermsModal } from '../../src/sections/Register/Modals';

import styled from 'styled-components'

const Footer = () => {
    const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);

    return <StyledFooter>
        <TermsModal
            visible={isTermsModalOpen}
            onClose={() => setIsTermsModalOpen(false)}
        />
        <div>
            <div className="columns">
                <div>
                </div>
                <div>
                    <span className={'f12'}><strong>SERVIÇO NESTLÉ AO CONSUMIDOR</strong></span>    
                    <span className={'f12'}><PhoneOutlined /> 0800-7702461</span>
                    <span className={'f12'}><strong>Seg-Sex, das 08h às 20h</strong></span>
                    <span className={'f16'}><MailOutlined /><strong> falecom@nestle.com.br</strong></span>
                    <span className={'f12'}>Caixa postal 79774/CEP:04601-970/São Paulo - SP - Brasil</span>
                </div>
                <div>
                    <a href="https://mail-nestle.s3-sa-east-1.amazonaws.com/termos-de-uso-nestle.pdf" target="_blank" rel="noopener noreferrer"><strong>Termos de uso</strong></a>
                    <a href="https://www.nestle.com.br/politica-de-privacidade" target="_blank" rel="noopener noreferrer"><strong>Política de privacidade</strong></a>
                </div>
                <div>
                    <span className={'f16'}>Quer saber mais sobre os produtos?</span>
                    <a href="https://www.nutren.com.br" target="_blank" rel="noopener noreferrer">www.nutren.com.br</a>
                    <a href="https://www.fibermais.com.br" target="_blank" rel="noopener noreferrer">www.fibermais.com.br</a>
                </div>
            </div>
            <div className="rights">
                <span>@ Nestlé Health Science - 2020 - Todos os Direitos Reservados</span>
            </div>
        </div>
        <div>
            <div className="doubts">
                <span>Para mais dúvidas do programa ou produtos, acesse:</span>
                <a href="https://www.nestle.com.br/converse-com-a-gente" target="_blank" rel="noopener noreferrer">
                    https://www.nestle.com.br/converse-com-a-gente
                </a>
            </div>
        </div>
    </StyledFooter>
}

const StyledFooter = styled.div`
    display: flex;
    flex-direction: column;
    color: white;

    > div {
        width: 100vw;
        display: flex;
        flex-direction: column;
        color: white;
        align-items: center;
    }

    > div:nth-child(1) {
        display: flex;
        flex-direction: column;
        background-color: ${props => props.theme.darkBlue};

        div {
            display: flex;
            flex-direction: column;
        }
    }

    > div:nth-child(2) {
        display: flex;
        flex-direction: column;
        background-color: ${props => props.theme.blue};

        div {
            display: flex;
            flex-direction: column;
        }
    }

    .columns,
    .rights,
    .doubts {
        max-width: 900px;
        width: 100%;
        flex-wrap: wrap;
    }

    > div:nth-child(1) .columns {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-top: 20px;

        div {
            display: flex;
            flex-direction: column;
            padding: 10px;
            margin-bottom: 10px;
        }
    }

    > div:nth-child(1) .rights {
        display: flex;
        justify-content: flex-start;
        padding: 20px 10px;
        border-top: solid 1px ${props => props.theme.blue};
    }

    > div:nth-child(2) .doubts {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 10px 0;
        font-size: 16px;
        border-top: solid 1px ${props => props.theme.blue};
        text-align: center;

        a {
            color: ${props => props.theme.darkBlue};
        }
    }

    .f12 {
        font-size: 12px;
    }

    .f16 {
        font-size: 16px;
    }
`

export default Footer;