import React from 'react';
import { Collapse } from 'antd';
import { PlayCircleFilled } from '@ant-design/icons';

import questions from './questions';

import styled from 'styled-components'

const { Panel } = Collapse;

const FAQ = () => {
    return <StyledFAQ id="faq">
        <h1>PERGUNTAS FREQUENTES</h1>
        <div>
            <Collapse
                expandIconPosition="right"
                expandIcon={() => <PlayCircleFilled
                    rotate={90}
                    style={{ backgroundColor: 'white', borderRadius: '50%', fontSize: 16 }}
                />}
                className="custom-collapse"
            >
                {
                    questions.map((question, index) => (
                        <Panel
                            header={question.question}
                            key={index + 1}
                            className="custom-panel"
                        >
                            <p>{question.answer}</p>
                        </Panel>
                    ))
                }
            </Collapse>
        </div>
        
    </StyledFAQ>
}

const StyledFAQ = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${props => props.theme.fadedGray};

    > * {
        width: 100%;
        max-width: 900px;
    }

    span {
        text-align: center;
    }

    .custom-collapse {
        border: none;
        background-color: ${props => props.theme.fadedGray};
    }

    .custom-panel {
        margin-bottom: 24px;
        border: none;
        background-color: ${props => props.theme.mediumGray};   
    }

    .custom-panel .ant-collapse-header {
        font-size: 16px;
        color: ${props => props.theme.darkBlue};
    }
`;

export default FAQ;