import React, { useState } from 'react';
import { Form, Input, Button, Checkbox } from 'antd';
import { ConfirmModal, SuccessModal, ErrorModal, TermsModal, WarningModal } from './Modals';
import InputMask from "react-input-mask";
import moment from 'moment';

import { registerUser, attachVoucherToUser } from '../../requestConfigs';

import { isValidCPF, getBrasilUfCode, isValidDDD, validateEmail } from './services';

import styled from 'styled-components'

const RegisterUserForm = ({ cupomCode, resetForm }) => {
    const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
    const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false);
    const [isWarningModalVisible, setIsWarningModalVisible] = useState(false);
    const [warningModalMessage, setWarningModalMessage] = useState('');
    const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);
    const [isErrorModalVisible, setIsErrorModalVisible] = useState(false);
    const [formValues, setFormValues] = useState({});
    const [loading, setLoading] = useState(false);

    const getTransformFormValues = () => {
        const cpf = formValues.cpf.replace(/\.|-|\s/g, '');
        const [, ano, mes, dia] = formValues.dataNascimento.match(/(\d{4})-(\d{2})-(\d{2})/);

        return {
            ...formValues,
            autorizaContato: formValues.autorizaContatoEmail ? "S" : "N",
            numero: "00",
            codigoCredenciado: "1",
            cpf,
            dataNascimento: `${dia}/${mes}/${ano}`,
        }
    }

    const onFinish = (values) => {
        setFormValues(values);
        setIsConfirmModalVisible(true);
    }

    const attachCupomCodeToUser = () => {
        return attachVoucherToUser({
            'nome': getTransformFormValues().nome,
            'email': getTransformFormValues().email
        }, getTransformFormValues().cpf, cupomCode);
    }

    const showErrorModal = () => {
        setIsConfirmModalVisible(false);
        setIsErrorModalVisible(true);
    }

    const getWarningModalMessage = (warningType) => {
        switch (warningType) {
            case "Não existe":
                return "O cupom que você esta tentando utilizar não existe."
            case "Cancelado":
                return "O cupom que você esta tentando utilizar foi cancelado."
            case "Expirado":
                return "O cupom que você esta tentando utilizar está expirado."
            case "Já utilizado":
                return "Já existe outro CPF associado ao cupom informado."
            case "Já associado":
                return "Esse voucher já foi associado a esse CPF."
            case "Associado":
                return "Seu cadastro foi associado ao cupom informado."
            default:
                return 'Erro ao associar cupom, entre em contato com o suporte.';
        }
    }

    const showWarningModal = (warningType) => {
        const modalMessage = getWarningModalMessage(warningType);
        setWarningModalMessage(modalMessage);
        setIsWarningModalVisible(true);
    }

    const hideWarningModal = () => {
        setWarningModalMessage('message');
        setIsWarningModalVisible(false);
    }

    const throwInvalidCupomError = (errMessage) => {
        const err = new Error();

        err.type = 'invalid cupom';
        err.message = errMessage;
        throw err;
    }

    const onSubmitConfirm = () => {
        setLoading(true);
        registerUser(getTransformFormValues())
            .then(res => {
                return attachCupomCodeToUser();
            })
            .then(async res => {
                const body = await res.json();
                if (res.status === 422) throwInvalidCupomError(body.message);
                setLoading(false);
                setIsConfirmModalVisible(false);
                setIsSuccessModalVisible(true);
            })
            .catch(e => {
                setLoading(false);
                setIsConfirmModalVisible(false);
                if (e.type === 'invalid cupom') showWarningModal(e.message);
                else showErrorModal();
            });
    }

    return <StyledUserInfosForm>
        <ConfirmModal
            visible={isConfirmModalVisible}
            onOk={onSubmitConfirm}
            loading={loading}
            onClose={() => { setIsConfirmModalVisible(false) }}
        />
        <SuccessModal
            visible={isSuccessModalVisible}
            onClose={() => {
                setIsSuccessModalVisible(false);
                resetForm();
            }}
        />
        <ErrorModal
            visible={isErrorModalVisible}
            onClose={() => { setIsErrorModalVisible(false) }}
        />
        <TermsModal
            visible={isTermsModalOpen}
            onClose={() => setIsTermsModalOpen(false)}
        />
        <WarningModal
            visible={isWarningModalVisible}
            onClose={hideWarningModal}
            message={warningModalMessage}
        />
        <Form
            onFinish={onFinish}
        >
            <div className='row'>
                <Form.Item
                    label="NOME COMPLETO:"
                    name="nome"
                    rules={[{ required: true, message: 'Por favor insira seu nome.' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="E-MAIL:"
                    name="email"
                    rules={[
                        { required: true, message: 'Por favor insira seu email.' },
                        ({ getFieldValue }) => ({
                            validator(rule, value) {
                                if (!value || validateEmail(value)) {
                                    return Promise.resolve();
                                }
                                return Promise.reject('Digite um e-mail valido.');
                            },
                        })
                    ]}
                >
                    <Input type="email"/>
                </Form.Item>
            </div>
            <div className='row'>
                <Form.Item
                    label="DATA DE NASCIMENTO:"
                    name="dataNascimento"
                    rules={[
                        { required: true, message: 'Por favor insira sua data de nascimento.' },
                        ({ getFieldValue }) => ({
                            validator(rule, value) {
                                let a = moment(value);
                                let b = moment();
                                
                                if (a.year() < 1900) {
                                    return Promise.reject('Digite um ano válido.')
                                }
                                
                                if (!value || b.diff(a, 'years') > 18) {
                                    return Promise.resolve();
                                }

                                return Promise.reject('Você precisa ser maior de idade.');
                            },
                        }),
                    ]}
                >
                    <Input type="date"/>
                </Form.Item>
                <Form.Item
                    label="CPF:"
                    name="cpf"
                    rules={[
                        { required: true, message: 'Por favor insira seu cpf.' },
                        ({ getFieldValue }) => ({
                            validator(rule, value) {
                                if (!value || isValidCPF(value)) {
                                    return Promise.resolve();
                                }
                                return Promise.reject('Este não é um cpf válido.');
                            },
                        }),
                    ]}
                >
                    <InputMask mask={"999.999.999-99"}>
                        {() => <Input />}
                    </InputMask>
                </Form.Item>
            </div>            
            <div className='row'>
                <Form.Item
                    label="CELULAR:"
                    name="celular"
                    rules={[
                        { required: true, message: 'Por favor insira seu celular.' },
                        ({ getFieldValue }) => ({
                            validator(rule, value) {
                                const dddMatch = value.match(/^\((\d{2})\)/);
                                const firstDigitMatch = value.match(/\(\d{2}\) (\d)/);

                                if (dddMatch && !isValidDDD(dddMatch[1])) {
                                    return Promise.reject('DDD inválido.');
                                }

                                if (firstDigitMatch && firstDigitMatch[1] !== '9') {
                                    return Promise.reject('O primeiro dígito deve ser 9.');
                                }

                                if (value.replace(/\(|\)|_|\s/g, "").length === 0) {
                                    return Promise.resolve();
                                }

                                if (value.replace(/\(|\)|_|\s/g, "").length < 11) {
                                    return Promise.reject('Celular inválido.');
                                }

                                return Promise.resolve();
                            },
                        }),
                    ]}
                >
                    <InputMask mask={"(99) 99999 9999"}>
                        {() => <Input />}
                    </InputMask>
                </Form.Item>
                <Form.Item
                    label="GÊNERO:"
                    name="sexo"
                    rules={[{ required: true, message: 'Por favor insira seu gênero.' }]}
                >
                    <select className="custom-select">
                        <option value=""></option>
                        <option value="M">Masculino</option>
                        <option value="F">Feminino</option>
                        <option value="O">Outro</option>
                    </select>
                </Form.Item>
            </div>
            <div className='row'>
                <Form.Item
                    label="ESTADO:"
                    name="uf"
                    rules={[{ required: true, message: 'Por favor insira seu estado.' }]}
                >
                    <select className="custom-select">
                        <option value={""}></option>
                        {
                            getBrasilUfCode().map(uf => <option value={uf}>{uf}</option>)
                        }
                    </select>
                </Form.Item>
                <Form.Item
                    label="CIDADE:"
                    name="cidade"
                    rules={[{ required: true, message: 'Por favor insira sua cidade.' }]}
                >
                    <Input />
                </Form.Item>
            </div>
            <Form.Item name="termosDeUso" valuePropName="checked" rules={[
                    ({ getFieldValue }) => ({
                        validator(rule, value) {
                            if (value) {
                                return Promise.resolve();
                            }
                            return Promise.reject('Para prosseguir você deve aceitar os termos de uso.');
                        },
                    }),
                ]}>
                <Checkbox defaultChecked={false}>*Aceito as <a href="https://www.nestle.com.br/politica-de-privacidade" target="_blank" rel="noopener noreferrer">Políticas de Privacidade</a> e os <a href='https://mail-nestle.s3-sa-east-1.amazonaws.com/termos-de-uso-nestle.pdf' target='_blank' rel="noopener noreferrer">Termos do Programa de Desconto Nestlé</a></Checkbox>
            </Form.Item>
            <Form.Item name="autorizaContatoEmail" valuePropName="checked">
                <Checkbox>
                    **Concordo que a Nestlé use meus dados de contato e interações para me mandar comunicações de marketing
                </Checkbox>
            </Form.Item>
            <Form.Item name="autorizaContatoSMS" valuePropName="checked">
                <Checkbox>
                    ACEITA RECEBER COMUNICAÇÃO POR SMS?
                </Checkbox>
            </Form.Item>
            <div className="row-reverse">
                <Button
                     htmlType="submit"
                >
                    ENVIAR
                </Button>
            </div>
        </Form>
        <div className="fields-legend">
            <span>*Todos os campos são de preenchimento obrigatório</span>
            <span>**Seu consentimento para este fim é voluntário e você é livre para retirá-lo a qualquer momento</span>
        </div>
    </StyledUserInfosForm>
}

const StyledUserInfosForm = styled.div`
    width: 900px;
    max-width: 100vw;
    position: relative;
    padding: 32px;
    background-color: ${props => props.theme.lightGray};

    .row {
        display: flex;

        > div {
            display: flex;
            flex: 1;
        }

        > div:nth-child(1) {
            margin-right: 16px;
        }
    }

    .row-reverse {
        display: flex;
        flex-direction: row-reverse;
    }

    .ant-form-item {
        margin-bottom: 16px;
    }

    label {
        color: ${props => props.theme.darkBlue};
    }
    
    label::before {
        color: ${props => props.theme.darkBlue} !important;
    }

    input,
    select {
        background-color: transparent;
        border-radius: 0;
        border-width: 0;
        border-bottom-width: 2px;
        border-color: white;
        width: 100%;
        height: 32px;
    }

    button {
        padding: 12px;
        height: auto;
        background-color: ${props => props.theme.darkBlue};
        color: white;
        width: 120px;
        align-self: flex-end;
        position: absolute;
        margin-bottom: 10px;
        bottom: 20px;
    }

    .fields-legend span {
        display: block;
        font-size: 10px;
        color: ${props => props.theme.darkBlue};
    }

    @media (max-width: 600px) {
        .row {
            flex-direction: column;
        }

        button {
            position: relative;
        }
    }
`

export default RegisterUserForm;